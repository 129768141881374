import { render, staticRenderFns } from "./assignOrganisation.vue?vue&type=template&id=36d45c9b"
import script from "./assignOrganisation.vue?vue&type=script&lang=js"
export * from "./assignOrganisation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_1e0d5e4c/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36d45c9b')) {
      api.createRecord('36d45c9b', component.options)
    } else {
      api.reload('36d45c9b', component.options)
    }
    module.hot.accept("./assignOrganisation.vue?vue&type=template&id=36d45c9b", function () {
      api.rerender('36d45c9b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modal/assignOrganisation.vue"
export default component.exports