var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "learning-form-header flex-wrap" }, [
      _c("h4", [_vm._v("LLM Email Interaction")]),
      _c("div", { staticClass: "learning-quiz-btn" }, [
        _vm.status == "published"
          ? _c(
              "button",
              {
                staticClass: "btn header-btn secondary",
                on: {
                  click: function ($event) {
                    return _vm.publishLO("published")
                  },
                },
              },
              [_vm._v(" Save ")]
            )
          : _vm._e(),
        _vm.status == "drafted"
          ? _c(
              "button",
              {
                staticClass: "btn header-btn primary",
                on: {
                  click: function ($event) {
                    return _vm.publishLO("published")
                  },
                },
              },
              [_vm._v(" Publish LO ")]
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", { staticClass: "learning-lo-tabs" }, [
      _c("div", { staticClass: "setup-container" }, [
        _c("ul", [
          _c(
            "li",
            { class: { active: _vm.isActiveTab === "basic-information" } },
            [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.switchTab("basic-information")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "check-box" }),
                  _c("span", [_vm._v("Basic Information")]),
                ]
              ),
            ]
          ),
          _c("li", { class: { active: _vm.isActiveTab === "kb-setup" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("kb-setup")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Knowledge Base Setup")]),
              ]
            ),
          ]),
          _c("li", { class: { active: _vm.isActiveTab === "prompts-setup" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("prompts-setup")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Prompts Setup")]),
              ]
            ),
          ]),
          _c("li", { class: { active: _vm.isActiveTab === "chat-testing" } }, [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    return _vm.switchTab("chat-testing")
                  },
                },
              },
              [
                _c("span", { staticClass: "check-box" }),
                _c("span", [_vm._v("Testing")]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "learning-quiz-content" },
      [
        _vm.isActiveTab == "basic-information"
          ? _c(
              "div",
              { staticClass: "setup-content" },
              [
                _vm._m(0),
                _c("ValidationObserver", { ref: "form" }, [
                  _c("form", { staticClass: "w-100" }, [
                    _c("div", { staticClass: "row custom-row" }, [
                      _c("div", { staticClass: "col-sm-5" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Interaction title")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Interaction Title",
                                rules: "required|max:100",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.name,
                                              expression: "name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.name = $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1088328446
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Administrative notes")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.adminNotes,
                                              expression: "adminNotes",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "Description",
                                            id: "notes",
                                            rows: "4",
                                          },
                                          domProps: { value: _vm.adminNotes },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.adminNotes =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2521843802
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Character Name")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.characterName,
                                              expression: "characterName",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "character-name",
                                            placeholder:
                                              "Add your character's name",
                                          },
                                          domProps: {
                                            value: _vm.characterName,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.characterName =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3292515161
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Character Pronouns")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.characterPronouns,
                                              expression: "characterPronouns",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "character-name",
                                            placeholder:
                                              "Add your character's name",
                                          },
                                          domProps: {
                                            value: _vm.characterPronouns,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.characterPronouns =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                448789593
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Character Age")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.characterAge,
                                              expression: "characterAge",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "character-age",
                                            placeholder:
                                              "Add your character's age",
                                          },
                                          domProps: { value: _vm.characterAge },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.characterAge =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                888184121
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Character Job")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "Administrative Notes",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.characterJob,
                                              expression: "characterJob",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "character-job",
                                            placeholder:
                                              "Add your character's job",
                                          },
                                          domProps: { value: _vm.characterJob },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.characterJob =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2102343609
                              ),
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Character Image")]),
                          _vm.characterImageURL
                            ? _c("img", {
                                staticClass: "character-image",
                                attrs: { src: _vm.characterImageURL },
                              })
                            : _vm._e(),
                          _c("input", {
                            ref: "fileInput",
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              accept: "image/jpeg, image/png",
                            },
                            on: { change: _vm.uploadImage },
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "btn header-btn secondary small-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.fileInput.click()
                                },
                              },
                            },
                            [_vm._v(" Upload Character Image ")]
                          ),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("p", [_vm._v("Conversation Characteristics")]),
                          _c("div", [
                            _c(
                              "select",
                              {
                                staticClass: "form-select",
                                on: {
                                  change: function ($event) {
                                    return _vm.setCharacteristics($event)
                                  },
                                },
                              },
                              _vm._l(
                                _vm.optionsCharacteristics,
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.id,
                                      domProps: {
                                        value: option.text,
                                        selected:
                                          option.text ===
                                          _vm.characterCharacteristics,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(option.text) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("p", [_vm._v("Character Stance")]),
                          _c("div", [
                            _c("div", { staticClass: "form-check" }, [
                              _c("label", { staticClass: "form-check-label" }, [
                                _vm._v("Correct/Incorrect"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  name: "correct/incorrect",
                                  value: "correct",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "correct"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "correct"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-check" }, [
                              _c("label", { staticClass: "form-check-label" }, [
                                _vm._v("Opinion/Argument"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  name: "opinion/argument",
                                  value: "opinion",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "opinion"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "opinion"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-check" }, [
                              _c("label", { staticClass: "form-check-label" }, [
                                _vm._v("I understand/I don't understand"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  name: "correct/incorrect",
                                  value: "understand",
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.characterStance,
                                    "understand"
                                  ),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "understand"
                                  },
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "form-check" }, [
                              _c("label", { staticClass: "form-check-label" }, [
                                _vm._v("GPT"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.characterStance,
                                    expression: "characterStance",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "radio",
                                  name: "gpt",
                                  value: "gpt",
                                },
                                domProps: {
                                  checked: _vm._q(_vm.characterStance, "gpt"),
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.characterStance = "gpt"
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", { staticClass: "form-check-label" }, [
                              _vm._v("Email Iterations"),
                            ]),
                            _c("small", [
                              _vm._v(
                                "If value is set to 0, the email interaction has no limit"
                              ),
                            ]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "llm_email_iterations",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.llmEmailIterations,
                                              expression: "llmEmailIterations",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "llm_email_iterations",
                                            placeholder:
                                              "Add the amount of email iterations",
                                          },
                                          domProps: {
                                            value: _vm.llmEmailIterations,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.llmEmailIterations =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1881934688
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group custom-control custom-checkbox",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.reattemptsEnabled,
                                  expression: "reattemptsEnabled",
                                },
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "reattemptsEnabled",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.reattemptsEnabled)
                                  ? _vm._i(_vm.reattemptsEnabled, null) > -1
                                  : _vm.reattemptsEnabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.reattemptsEnabled,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.reattemptsEnabled = $$a.concat([
                                          $$v,
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.reattemptsEnabled = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.reattemptsEnabled = $$c
                                  }
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "reattemptsEnabled" },
                              },
                              [_vm._v("Enable Reattempts")]
                            ),
                          ]
                        ),
                        _vm.reattemptsEnabled
                          ? _c(
                              "div",
                              { staticClass: "form-group custom-control" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.maxReattempts,
                                      expression: "maxReattempts",
                                    },
                                  ],
                                  attrs: {
                                    type: "number",
                                    id: "maxReattempts",
                                    placeholder: "Max Reattempts",
                                  },
                                  domProps: { value: _vm.maxReattempts },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.maxReattempts = $event.target.value
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "p",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "enableLlmAssessments" },
                            },
                            [_vm._v("Enable LLM Assessments")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.enableLlmAssessments,
                                expression: "enableLlmAssessments",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              id: "enableLlmAssessments",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.enableLlmAssessments)
                                ? _vm._i(_vm.enableLlmAssessments, null) > -1
                                : _vm.enableLlmAssessments,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.enableLlmAssessments,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.enableLlmAssessments = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.enableLlmAssessments = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.enableLlmAssessments = $$c
                                }
                              },
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("CC Characters")]),
                            _c("ValidationProvider", {
                              attrs: {
                                name: "llm_email_iterations",
                                rules: "required",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.newccCharacter,
                                              expression: "newccCharacter",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "invalid"
                                              : "",
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "newccCharacter",
                                            placeholder:
                                              "Add a new CC character",
                                          },
                                          domProps: {
                                            value: _vm.newccCharacter,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.newccCharacter =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4084126320
                              ),
                            }),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn header-btn secondary small-btn",
                                on: { click: _vm.addNewccCharacter },
                              },
                              [_vm._v(" Add new CC Character ")]
                            ),
                            _vm._l(
                              _vm.ccCharacters,
                              function (character, index) {
                                return _c("div", { key: index }, [
                                  _c("div", { staticClass: "cc-character" }, [
                                    _c("p", [_vm._v(_vm._s(character))]),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeccCharacter(index)
                                          },
                                        },
                                      },
                                      [_vm._v("X")]
                                    ),
                                  ]),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("div", { staticClass: "col-sm-7" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("p", [_vm._v("Learner Description")]),
                            _c("ValidationProvider", {
                              attrs: { name: "Description", rules: "required" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors }) {
                                      return [
                                        _c("vue-editor", {
                                          class: [
                                            _vm.isActive && errors[0]
                                              ? "form-control invalid"
                                              : "form-control",
                                          ],
                                          attrs: {
                                            id: "Description",
                                            placeholder: "Add a Description",
                                          },
                                          model: {
                                            value: _vm.description,
                                            callback: function ($$v) {
                                              _vm.description = $$v
                                            },
                                            expression: "description",
                                          },
                                        }),
                                        errors[0]
                                          ? _c(
                                              "span",
                                              {
                                                class: [
                                                  _vm.isActive ? "invalid" : "",
                                                ],
                                                style: [
                                                  _vm.isActive
                                                    ? { display: "block" }
                                                    : { display: "none" },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    errors[0].replace(
                                                      "The ",
                                                      ""
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                927984928
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "col-12 pt-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end mb-4" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                attrs: { href: "javascript:void(0);" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveLO("drafted", 1)
                                  },
                                },
                              },
                              [_vm._v(" Save ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "btn-container justify-content-end" },
                          [
                            _c("a", { staticClass: "delete-btn" }, [
                              _c("div", { staticClass: "delete-box" }, [
                                _c("span", { staticClass: "icon-delete" }),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text",
                                  on: {
                                    click: function ($event) {
                                      return _vm.archiveLO("archived")
                                    },
                                  },
                                },
                                [_vm._v("Archive this Learning Object(LO)")]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.isActiveTab == "kb-setup"
          ? _c("div", { staticClass: "setup-content" }, [
              _vm._m(1),
              _c("div", { staticClass: "knowledge-base-container" }, [
                _c("div", { staticClass: "knowledge-base-item" }, [
                  _c("h5", [_vm._v("General Knowledge base")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.generalKnowledgeBase,
                        expression: "generalKnowledgeBase",
                      },
                    ],
                    staticClass: "text-area",
                    attrs: { placeholder: "Add Global General Knowledge base" },
                    domProps: { value: _vm.generalKnowledgeBase },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.generalKnowledgeBase = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "knowledge-base-item" }, [
                  _c("h5", [_vm._v("Global Narrative Knowledge base")]),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.narrativeKnowledgeBase,
                        expression: "narrativeKnowledgeBase",
                      },
                    ],
                    staticClass: "text-area narrativeKnowledgeBaseItem",
                    attrs: {
                      placeholder: "Add Global Narrative Knowledge base",
                    },
                    domProps: { value: _vm.narrativeKnowledgeBase },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.narrativeKnowledgeBase = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "kb-btn-container" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn primary",
                    on: {
                      click: function ($event) {
                        return _vm.saveLO("drafted", 1)
                      },
                    },
                  },
                  [_vm._v(" Save ")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.isActiveTab === "prompts-setup" && this.enableLlmAssessments
          ? _c("LlmEmailSkillAssessment", {
              attrs: {
                llmEmailData: _vm.llmEmailData,
                llmType: _vm.llmType,
                status: _vm.status,
              },
              on: { saveLO: _vm.saveLO, archiveLO: _vm.archiveLO },
            })
          : _vm._e(),
        _vm.isActiveTab == "prompts-setup" && !this.enableLlmAssessments
          ? _c("div", { staticClass: "setup-content" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "prompts-container prompt-reaction-container" },
                [
                  _vm._m(3),
                  _c("div", { staticClass: "prompts-body" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Sentiment")]),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Prompt Reaction Sentiment",
                            rules: "required",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.promptReactionSentiment,
                                          expression: "promptReactionSentiment",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: [
                                        _vm.isActive && errors[0]
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "text",
                                        id: "character-characteristics",
                                        placeholder:
                                          "Add your Prompt Reaction Sentiment",
                                      },
                                      domProps: {
                                        value: _vm.promptReactionSentiment,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.promptReactionSentiment =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                errors[0].replace("The ", "")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1655429728
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [_vm._v("Emotion")]),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "Character characteristics",
                            rules: "required",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ errors }) {
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.promptReactionEmotion,
                                          expression: "promptReactionEmotion",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      class: [
                                        _vm.isActive && errors[0]
                                          ? "invalid"
                                          : "",
                                      ],
                                      attrs: {
                                        type: "text",
                                        id: "character-characteristics",
                                        placeholder:
                                          "Add your Prompt Reaction Emotion",
                                      },
                                      domProps: {
                                        value: _vm.promptReactionEmotion,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.promptReactionEmotion =
                                            $event.target.value
                                        },
                                      },
                                    }),
                                    errors[0]
                                      ? _c(
                                          "span",
                                          {
                                            class: [
                                              _vm.isActive ? "invalid" : "",
                                            ],
                                            style: [
                                              _vm.isActive
                                                ? { display: "block" }
                                                : { display: "none" },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                errors[0].replace("The ", "")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1818847916
                          ),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn primary",
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          return _vm.saveLO("drafted", 1)
                        },
                      },
                    },
                    [_vm._v(" Save Prompt Reaction Settings ")]
                  ),
                ]
              ),
              _c("div", { staticClass: "prompts-container" }, [
                _vm._m(4),
                _c("div", { staticClass: "prompts-body" }, [
                  _c(
                    "div",
                    { staticClass: "side-menu" },
                    [
                      _c(
                        "draggable",
                        {
                          on: {
                            start: function ($event) {
                              _vm.isDragging = true
                            },
                            end: _vm.onEnd,
                          },
                          model: {
                            value: _vm.promptData,
                            callback: function ($$v) {
                              _vm.promptData = $$v
                            },
                            expression: "promptData",
                          },
                        },
                        _vm._l(_vm.promptData, function (prompt, index) {
                          return _c(
                            "ul",
                            { key: prompt.id, staticClass: "side-prompt" },
                            [
                              _c(
                                "li",
                                {
                                  staticClass: "menu-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.choosePrompt(index)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        prompt.title || `Prompt: ${prompt.id}`
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "delete-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deletePrompt(prompt.id)
                                    },
                                  },
                                },
                                [_vm._v(" X ")]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c("hr"),
                      _c(
                        "button",
                        {
                          staticClass: "btn add-prompt-btn",
                          on: { click: _vm.addPrompt },
                        },
                        [_vm._v(" Add Prompt ")]
                      ),
                    ],
                    1
                  ),
                  _vm.showPrompts
                    ? _c("div", { staticClass: "prompts-edit" }, [
                        _c("div", { staticClass: "edit-body" }, [
                          _c("h4", [_vm._v("Knowledge base form")]),
                          _c("form", { staticClass: "kb_form" }, [
                            _c("div", { staticClass: "kb_form--item" }, [
                              _c("label", [
                                _vm._v(
                                  "Editing: " +
                                    _vm._s(
                                      this.chosenPrompt.title || "New Prompt"
                                    )
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Prompt Title")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.chosenPrompt.title,
                                      expression: "chosenPrompt.title",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    placeholder: "Input prompt Title here",
                                  },
                                  domProps: { value: _vm.chosenPrompt.title },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.chosenPrompt,
                                        "title",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [
                                  _vm._v("Independent / Dependendent Prompt"),
                                ]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.chosenPrompt.independent_prompt,
                                        expression:
                                          "chosenPrompt.independent_prompt",
                                      },
                                    ],
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "independent_prompt",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.optionsIndependentDependent,
                                    function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option.id,
                                          domProps: {
                                            value: option.value,
                                            selected:
                                              option.value ===
                                              _vm.chosenPrompt
                                                .independent_prompt,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(option.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Prompt Type")]),
                                _c(
                                  "select",
                                  {
                                    staticClass: "custom-select",
                                    on: {
                                      change: function ($event) {
                                        return _vm.setPassFail($event)
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.optionsPassFail,
                                    function (option) {
                                      return _c(
                                        "option",
                                        {
                                          key: option.id,
                                          domProps: {
                                            value: option.value,
                                            selected:
                                              option.value ==
                                              _vm.chosenPassFail.value,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(option.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Add Prompt")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.chosenPrompt.prompt,
                                      expression: "chosenPrompt.prompt",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { placeholder: "Input prompt here" },
                                  domProps: { value: _vm.chosenPrompt.prompt },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.chosenPrompt,
                                        "prompt",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Tone/Triggers")]),
                                _c("label", [_vm._v("Trigger Response")]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.chosenPrompt.trigger_response,
                                        expression:
                                          "chosenPrompt.trigger_response",
                                      },
                                    ],
                                    ref: "trigger_response",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "trigger_response",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [_vm._v(" Please select a response ")]
                                    ),
                                    _c("option", [_vm._v("Hint")]),
                                    _c("option", [_vm._v("Answer")]),
                                    _c("option", [_vm._v("None")]),
                                  ]
                                ),
                                _c("label", [_vm._v("Trigger Pass")]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chosenPrompt.trigger_pass,
                                        expression: "chosenPrompt.trigger_pass",
                                      },
                                    ],
                                    ref: "trigger_pass",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "trigger_pass",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [_vm._v(" Please select a Pass trigger ")]
                                    ),
                                    _c("option", [_vm._v("Sadness")]),
                                    _c("option", [_vm._v("Happiness")]),
                                    _c("option", [_vm._v("Fear")]),
                                    _c("option", [_vm._v("Anger")]),
                                    _c("option", [_vm._v("Surprise")]),
                                    _c("option", [_vm._v("Rude")]),
                                    _c("option", [_vm._v("None")]),
                                  ]
                                ),
                                _c("label", [_vm._v("Trigger Fail")]),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chosenPrompt.trigger_fail,
                                        expression: "chosenPrompt.trigger_fail",
                                      },
                                    ],
                                    ref: "trigger_fail",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "trigger_fail",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [_vm._v(" Please select a Fail trigger ")]
                                    ),
                                    _c("option", [_vm._v("Sadness")]),
                                    _c("option", [_vm._v("Happiness")]),
                                    _c("option", [_vm._v("Fear")]),
                                    _c("option", [_vm._v("Anger")]),
                                    _c("option", [_vm._v("Surprise")]),
                                    _c("option", [_vm._v("Rude")]),
                                    _c("option", [_vm._v("None")]),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [
                                  _vm._v("Prompt Narrative Knowledge Base"),
                                ]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenPrompt
                                          .narrative_knowledge_base,
                                      expression:
                                        "chosenPrompt.narrative_knowledge_base",
                                    },
                                  ],
                                  attrs: {
                                    placeholder:
                                      "Input Prompt Narrative Knowledge Base here",
                                  },
                                  domProps: {
                                    value:
                                      _vm.chosenPrompt.narrative_knowledge_base,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.chosenPrompt,
                                        "narrative_knowledge_base",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Admin Notes")]),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.chosenPrompt.admin_notes,
                                      expression: "chosenPrompt.admin_notes",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    placeholder: "Input Admin Notes here",
                                  },
                                  domProps: {
                                    value: _vm.chosenPrompt.admin_notes,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.chosenPrompt,
                                        "admin_notes",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kb_form--item form-group form-checkbox",
                              },
                              [
                                _vm._m(5),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.chosenPrompt.optional_assessment,
                                      expression:
                                        "chosenPrompt.optional_assessment",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.chosenPrompt.optional_assessment
                                    )
                                      ? _vm._i(
                                          _vm.chosenPrompt.optional_assessment,
                                          null
                                        ) > -1
                                      : _vm.chosenPrompt.optional_assessment,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.chosenPrompt.optional_assessment,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "optional_assessment",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "optional_assessment",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "optional_assessment",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Feedback Request Type")]),
                                _vm._m(6),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.chosenPrompt
                                            .feedback_assessment_type,
                                        expression:
                                          "chosenPrompt.feedback_assessment_type",
                                      },
                                    ],
                                    ref: "feedback_request_type",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.chosenPrompt,
                                          "feedback_assessment_type",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { disabled: "", value: "" } },
                                      [
                                        _vm._v(
                                          " Please select a feedback request type "
                                        ),
                                      ]
                                    ),
                                    _c("option", [_vm._v("All")]),
                                    _vm.chosenPrompt.pass_fail === false
                                      ? _c("option", [_vm._v(" Hit ")])
                                      : _vm._e(),
                                    _c("option", [_vm._v("None")]),
                                  ]
                                ),
                              ]
                            ),
                            _vm.chosenPrompt.pass_fail === true
                              ? _c(
                                  "div",
                                  { staticClass: "kb_form--item form-group" },
                                  [
                                    _c("label", [
                                      _vm._v("Feedback Request Items"),
                                    ]),
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticClass: "kb kb-feedback-request",
                                        },
                                        [
                                          _c("label", [_vm._v("Pass")]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kb-feedback-request-input-container",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .feedback_assessment_pass_data
                                                        .label,
                                                    expression:
                                                      "feedback_assessment_pass_data.label",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Input Pass Item Label",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm
                                                      .feedback_assessment_pass_data
                                                      .label,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.feedback_assessment_pass_data,
                                                      "label",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .feedback_assessment_pass_data
                                                        .description,
                                                    expression:
                                                      "feedback_assessment_pass_data.description",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Input Pass Item Description",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm
                                                      .feedback_assessment_pass_data
                                                      .description,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.feedback_assessment_pass_data,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "kb kb-feedback-request",
                                        },
                                        [
                                          _c("label", [_vm._v("Fail")]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kb-feedback-request-input-container",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .feedback_assessment_fail_data
                                                        .label,
                                                    expression:
                                                      "feedback_assessment_fail_data.label",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Input Fail Item Label",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm
                                                      .feedback_assessment_fail_data
                                                      .label,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.feedback_assessment_fail_data,
                                                      "label",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm
                                                        .feedback_assessment_fail_data
                                                        .description,
                                                    expression:
                                                      "feedback_assessment_fail_data.description",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  placeholder:
                                                    "Input Fail Item Description",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm
                                                      .feedback_assessment_fail_data
                                                      .description,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.feedback_assessment_fail_data,
                                                      "description",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.chosenPrompt.pass_fail === false
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.assessmentItemsKey,
                                    staticClass: "kb_form--item form-group",
                                  },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass: "assessmentFeedbackTitle",
                                      },
                                      [
                                        _vm._v(" Feedback Request Items "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn primary",
                                            on: {
                                              click: _vm.addNewAssessmentItem,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Add New Feedback Request Item"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    Object.keys(_vm.assessmentItems).length ===
                                    0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "assessment-feedback-empty",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                "No feedback request items have been added yet."
                                              ),
                                            ]),
                                            _c("p", [
                                              _vm._v(
                                                "Click the button above to add a new feedback request item."
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "ul",
                                      _vm._l(
                                        _vm.assessmentItems,
                                        function (item, index) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              ref: "assessment_items",
                                              refInFor: true,
                                              staticClass:
                                                "kb kb-feedback-request",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kb-feedback-request-input-container",
                                                },
                                                [
                                                  _c("div", [
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assessment Item Label"
                                                      ),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: item.label,
                                                          expression:
                                                            "item.label",
                                                        },
                                                      ],
                                                      ref: `assessment-item-label-${index}`,
                                                      refInFor: true,
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder:
                                                          "Input Assessment Item Label",
                                                      },
                                                      domProps: {
                                                        value: item.label,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            item,
                                                            "label",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("label", [
                                                      _vm._v(
                                                        "Assessment Item Description"
                                                      ),
                                                    ]),
                                                    _c("textarea", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            item.description,
                                                          expression:
                                                            "item.description",
                                                        },
                                                      ],
                                                      ref: `assessment-item-description-${index}`,
                                                      refInFor: true,
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        placeholder:
                                                          "Input Assessment Item Description",
                                                      },
                                                      domProps: {
                                                        value: item.description,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            item,
                                                            "description",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("label", [
                                                      _vm._v("Item Level"),
                                                    ]),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            item.assessment_level,
                                                          expression:
                                                            "item.assessment_level",
                                                        },
                                                      ],
                                                      ref: `assessment-item-level-${index}`,
                                                      refInFor: true,
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        type: "number",
                                                        placeholder:
                                                          "Input Assessment Item Level",
                                                      },
                                                      domProps: {
                                                        value:
                                                          item.assessment_level,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            item,
                                                            "assessment_level",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "feedback-item-btn-container",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          ref: `assessment-item-${index}`,
                                                          refInFor: true,
                                                          staticClass:
                                                            "btn primary",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editAssessmentItem(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Edit ")]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn delete-kb",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteAssessmentItem(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" Delete ")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.addingNewAssessmentItem
                              ? _c("div", [
                                  _c("div", { staticClass: "divider" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group new-assessment-item-container",
                                    },
                                    [
                                      _c("p", [
                                        _vm._v("New Feedback Request Item"),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "new-assessment-form" },
                                        [
                                          _c("label", [
                                            _vm._v("Assessment Item Label"),
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.newItem.label,
                                                expression: "newItem.label",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Input Assessment Item Label",
                                            },
                                            domProps: {
                                              value: _vm.newItem.label,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.newItem,
                                                  "label",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("label", [
                                            _vm._v(
                                              "Assessment Item Description"
                                            ),
                                          ]),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.newItem.description,
                                                expression:
                                                  "newItem.description",
                                              },
                                            ],
                                            ref: `new-assessment-item-description`,
                                            staticClass: "form-control",
                                            attrs: {
                                              placeholder:
                                                "Input Assessment Item Description",
                                            },
                                            domProps: {
                                              value: _vm.newItem.description,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.newItem,
                                                  "description",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c("label", [_vm._v("Item Level")]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.newItem.assessment_level,
                                                expression:
                                                  "newItem.assessment_level",
                                              },
                                            ],
                                            ref: `new-assessment-item-level`,
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              placeholder:
                                                "Input Assessment Item Level",
                                            },
                                            domProps: {
                                              value:
                                                _vm.newItem.assessment_level,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.newItem,
                                                  "assessment_level",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "feedback-item-btn-container",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn primary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.saveAssessmentItem()
                                                },
                                              },
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn secondary",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelAssessmentItem()
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            !_vm.chosenPrompt.pass_fail
                              ? _c("h5", { staticClass: "assessmentKBTitle" }, [
                                  _vm._v(" Assessment Knowledge Base "),
                                ])
                              : _vm._e(),
                            !_vm.chosenPrompt.pass_fail
                              ? _c(
                                  "div",
                                  { staticClass: "kb_form--item form-group" },
                                  [
                                    _c("label", [_vm._v("Name")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.chosenPrompt
                                              .assessment_knowledgebase_name,
                                          expression:
                                            "chosenPrompt.assessment_knowledgebase_name",
                                        },
                                      ],
                                      ref: "assessment_knowledgebase_name",
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          "Input Assessment Knowledge Base Name here",
                                      },
                                      domProps: {
                                        value:
                                          _vm.chosenPrompt
                                            .assessment_knowledgebase_name,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.chosenPrompt,
                                            "assessment_knowledgebase_name",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            !_vm.chosenPrompt.pass_fail
                              ? _c(
                                  "div",
                                  { staticClass: "kb_form--item form-group" },
                                  [
                                    _c("div", [
                                      _c("label", [
                                        _vm._v("Knowledge Base Entries"),
                                      ]),
                                      _c(
                                        "ul",
                                        _vm._l(
                                          _vm.chosenPrompt.knowledge_base_items,
                                          function (assessment_kb, index) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                ref: "kb_entries",
                                                refInFor: true,
                                                staticClass: "kb",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    ref: `kbitem-${index}`,
                                                    refInFor: true,
                                                    staticClass: "entry-kb",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(assessment_kb) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kb-btn-container",
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        ref: `kbedit-${index}`,
                                                        refInFor: true,
                                                        staticClass:
                                                          "btn edit-kb",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editKnowledgeBaseItem(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Edit ")]
                                                    ),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn delete-kb",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteKnowledgeBaseItem(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Delete ")]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kb-input form-group" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.newAssKB,
                                                expression: "newAssKB",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder:
                                                "Input Assessment Knowledge Base here",
                                            },
                                            domProps: { value: _vm.newAssKB },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.newAssKB =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn primary",
                                              on: {
                                                click: _vm.addKnowledgeBaseItem,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " Add Knowledge Base Entry "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "kb_form--item form-group" },
                              [
                                _c("label", [_vm._v("Task")]),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.chosenPrompt.task,
                                      expression: "chosenPrompt.task",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Input task here",
                                  },
                                  domProps: { value: _vm.chosenPrompt.task },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.chosenPrompt,
                                        "task",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "kb_form--item kb_form--itemhori",
                              },
                              [
                                !_vm.chosenPrompt.pass_fail
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kb_item--column form-group",
                                      },
                                      [
                                        _c("label", [_vm._v("Threshold")]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.chosenPrompt.threshold,
                                              expression:
                                                "chosenPrompt.threshold",
                                            },
                                          ],
                                          ref: "thresholdRequired",
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: {
                                            value: _vm.chosenPrompt.threshold,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "threshold",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "kb_item--column form-group" },
                                  [
                                    _c("label", [_vm._v("Mandatory")]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.chosenPrompt.mandatory,
                                          expression: "chosenPrompt.mandatory",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.chosenPrompt.mandatory
                                        )
                                          ? _vm._i(
                                              _vm.chosenPrompt.mandatory,
                                              null
                                            ) > -1
                                          : _vm.chosenPrompt.mandatory,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.chosenPrompt.mandatory,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.chosenPrompt,
                                                  "mandatory",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.chosenPrompt,
                                                  "mandatory",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.chosenPrompt,
                                              "mandatory",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _c("div", { staticClass: "kb_item--column" }, [
                                  _c("label", [_vm._v("Question Needed")]),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.chosenPrompt.question_needed,
                                        expression:
                                          "chosenPrompt.question_needed",
                                      },
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.chosenPrompt.question_needed
                                      )
                                        ? _vm._i(
                                            _vm.chosenPrompt.question_needed,
                                            null
                                          ) > -1
                                        : _vm.chosenPrompt.question_needed,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.chosenPrompt.question_needed,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "question_needed",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.chosenPrompt,
                                                "question_needed",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.chosenPrompt,
                                            "question_needed",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn primary",
                                on: { click: _vm.savePrompt },
                              },
                              [_vm._v(" Save Prompt ")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.isActiveTab == "chat-testing"
          ? _c("div", { staticClass: "setup-content" }, [
              _c("div", { staticClass: "testing-header" }, [
                _c("h4", { staticClass: "head-title" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
                _c("h5", { domProps: { innerHTML: _vm._s(_vm.description) } }),
              ]),
              _vm.emailChain.length > 0
                ? _c("div", { staticClass: "email-area" }, [
                    _c(
                      "div",
                      { staticClass: "email-container" },
                      [
                        _vm._l(_vm.emailChain, function (email, index) {
                          return _c(
                            "div",
                            { key: email.id, staticClass: "email-chain" },
                            [
                              _c("div", { staticClass: "email-header" }, [
                                _c("p", [_vm._v(_vm._s(_vm.emailSubject))]),
                                _c("p", [
                                  _vm._v("To: " + _vm._s(email.toCharacter)),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "From: " + _vm._s(email.fromCharacter)
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "email-body" }, [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(email.response),
                                  },
                                }),
                              ]),
                              index + 1 !== _vm.emailChain.length
                                ? _c("div", { staticClass: "email-divider" }, [
                                    _c("div", [
                                      _vm._v(
                                        "Reply from " +
                                          _vm._s(email.toCharacter)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "email-body" },
                          [
                            _c("vue-editor", {
                              class: [
                                _vm.isActive && _vm.errors[0]
                                  ? "form-control invalid"
                                  : "form-control",
                              ],
                              attrs: { placeholder: "Add Email Body" },
                              model: {
                                value: _vm.emailBody,
                                callback: function ($$v) {
                                  _vm.emailBody = $$v
                                },
                                expression: "emailBody",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "email-footer" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn primary email-button",
                              on: { click: _vm.sendEmail },
                            },
                            [_vm._v("Send")]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
              _vm.emailChain.length === 0
                ? _c("div", { staticClass: "email-area" }, [
                    _c("div", { staticClass: "email-container" }, [
                      _c("div", { staticClass: "email-header" }, [
                        _c("p", [_vm._v(_vm._s(_vm.emailSubject))]),
                        _c("p", [
                          _vm._v("To: " + _vm._s(_vm.emailToCharacter)),
                        ]),
                        _c("p", [
                          _vm._v("From: " + _vm._s(_vm.emailFromCharacter)),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "email-body" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.emailSubject,
                                expression: "emailSubject",
                              },
                            ],
                            staticClass: "subject-line",
                            attrs: { type: "text", placeholder: "Add Subject" },
                            domProps: { value: _vm.emailSubject },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.emailSubject = $event.target.value
                              },
                            },
                          }),
                          _c("vue-editor", {
                            class: [
                              _vm.isActive && _vm.errors[0]
                                ? "form-control invalid"
                                : "form-control",
                            ],
                            attrs: { placeholder: "Add Email Body" },
                            model: {
                              value: _vm.emailBody,
                              callback: function ($$v) {
                                _vm.emailBody = $$v
                              },
                              expression: "emailBody",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "email-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn primary email-button",
                            on: { click: _vm.sendEmail },
                          },
                          [_vm._v("Send")]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Basic Information")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [
        _c("h4", { staticClass: "head-title" }, [
          _vm._v("Knowledge Base Setup"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex justify-content-between align-items-center" },
      [_c("h4", { staticClass: "head-title" }, [_vm._v("Prompts Setup")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prompts-header" }, [
      _c("h5", [_vm._v("Prompt Reaction Settings")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prompts-header" }, [
      _c("h4", [_vm._v("Lo Prompts")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("label", [_vm._v("Include this prompt in assessment")]),
      _c("p", [
        _vm._v(
          " Checking this option will include this prompt when assessing the entire ELM "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feedback-request-help-container" }, [
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          " This option determines how much feedback is provided to the user "
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "All" is selected, both the prompt text and the appropriate feedback request item will be provided. '
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "Hit" is selected, the appropriate feedback request item will be provided along with any part of the prompt text that the user successfully hit on. '
        ),
      ]),
      _c("p", { staticClass: "feedback-request-help" }, [
        _vm._v(
          ' If "None" is selected, only the appropriate feedback request item will be provided. '
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }